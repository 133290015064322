import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, CircularProgress, TextField, IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { fetchUsersFromOrganization } from "../../functions/users";
import { fetchOrganizationFromID, updateOrganizationRate } from "../../functions/organization";

export default function OrganizationModal({
  open,
  handleClose,
  org,
  organizations,
  isModal = true,
}) {
  const [organization, setOrganization] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false); 
  const [rate, setRate] = useState(""); 

  useEffect(() => {
    if (!org) return;

    const fetchOrganizationAndUsers = async () => {
      let organization = null;

      if (isModal) {
        organization = organizations.find(
          (organization) => organization.name.stringValue === org.name
        );
        if (organization) {
          setOrganization(organization);
          setRate(organization.rate.doubleValue.toFixed(2)); 

          try {
            setLoading(true);
            const fetchedUsers = await fetchUsersFromOrganization(
              organization.uid.stringValue
            );
            setUsers(fetchedUsers);
          } catch (error) {
            console.error("Error fetching users:", error);
          } finally {
            setLoading(false);
          }
        }
      } else {
        try {
          organization = await fetchOrganizationFromID(org);
          if (organization) {
            setOrganization(organization);
            setRate(organization.rate); 

            try {
              setLoading(true);
              const fetchedUsers = await fetchUsersFromOrganization(org);
              setUsers(fetchedUsers);
            } catch (error) {
              console.error("Error fetching users:", error);
            } finally {
              setLoading(false);
            }
          }
        } catch (error) {
          console.error("Error fetching organization:", error);
        }
      }
    };

    fetchOrganizationAndUsers();
  }, [org, isModal, organizations]);

  const handleRateChange = (event) => {
    const value = event.target.value;
    // Allow only valid double values
    if (/^\d*\.?\d*$/.test(value)) {
      setRate(value);
    }
  };

  const handleRateSave = async () => {
    if (!organization || !rate) return;

    try {
      setLoading(true);
      await updateOrganizationRate(organization.uid, parseFloat(rate)); 
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating rate:", error);
      alert("Failed to update the rate.");
    } finally {
      setLoading(false);
    }
  };

  if (!organization) return null;

  if (isModal) {
    return (
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 700,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "60%" }}>
            <Typography variant="h6" component="h2" sx={{ fontWeight: "bold" }}>
              {organization.name.stringValue}
            </Typography>
            {organization.uid && organization.uid.stringValue && (
              <Typography sx={{ mt: 1 }}>
                Organization ID: {organization.uid.stringValue}
              </Typography>
            )}
            {organization.joinDate && organization.joinDate.stringValue && (
              <Typography sx={{ mt: 1 }}>
                Join Date: {organization.joinDate.stringValue}
              </Typography>
            )}
            {organization.rate && organization.rate.doubleValue && (
              <Typography sx={{ mt: 1 }}>
                Electricity Rate: ${organization.rate.doubleValue.toFixed(2)}
              </Typography>
            )}

            {/*organization.region && organization.region.stringValue && (
              <Typography sx={{ mt: 1 }}>
                ISO Region: {organization.region.stringValue}
              </Typography>
            )*/}
            {organization.timezone && organization.timezone.stringValue && (
              <Typography sx={{ mt: 1 }}>
                Timezone: {organization.timezone.stringValue}
              </Typography>
            )}
            {organization.zipcode && organization.zipcode.stringValue && (
              <Typography sx={{ mt: 1 }}>
                Zipcode: {organization.zipcode.stringValue}
              </Typography>
            )}
          </Box>
          <Box sx={{ width: "40%", maxHeight: "300px", overflowY: "auto" }}>
            <Typography variant="h6" component="h2" sx={{ fontWeight: "bold" }}>
              Users
            </Typography>
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <CircularProgress />
              </Box>
            ) : users.length > 0 ? (
              users.map((user, index) => (
                <Typography key={index} sx={{ mt: 1 }}>
                  {user.email}
                </Typography>
              ))
            ) : (
              <Typography sx={{ mt: 1 }}>No users found.</Typography>
            )}
          </Box>
        </Box>
      </Modal>
    );
  } else {
    return (
      <Box
        sx={{
          width: 700,
          maxWidth: "1200px",
          padding: "20px",
          bgcolor: "background.paper",
          p: 4,
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6" component="h2" sx={{ fontWeight: "bold", mb: 2 }}>
          {organization.name}
        </Typography>
        {organization.uid && (
          <Typography sx={{ mb: 2 }}>
            Organization ID: {organization.uid}
          </Typography>
        )}
        {organization.joinDate && (
          <Typography sx={{ mb: 2 }}>
            Join Date: {organization.joinDate}
          </Typography>
        )}
        {/*organization.region && (
          <Typography sx={{ mb: 2 }}>
            ISO Region: {organization.region}
          </Typography>
        )*/}
        {organization.timezone && (
          <Typography sx={{ mb: 2 }}>
            Timezone: {organization.timezone}
          </Typography>
        )}
        {organization.zipcode && (
          <Typography sx={{ mb: 2 }}>
            Zipcode: {organization.zipcode}
          </Typography>
        )}
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          {isEditing ? (
            <TextField
              label="Rate"
              variant="outlined"
              value={rate}
              onChange={handleRateChange}
              sx={{ flexGrow: 1, mr: 2 }}
            />
          ) : (
            <Typography sx={{ flexGrow: 1 }}>
              Electricity Rate: ${rate}
            </Typography>
          )}
          <IconButton
            onClick={() => {
              if (isEditing) {
                handleRateSave();
              } else {
                setIsEditing(true);
              }
            }}
            color={isEditing ? "primary" : "default"}
          >
            {isEditing ? <SaveIcon /> : <EditIcon />}
          </IconButton>
        </Box>
      </Box>
    );
  }
}
