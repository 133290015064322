import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  setDoc,
  updateDoc
} from "firebase/firestore";
import db from "../firebase";
import moment from "moment";
import { uid } from "../firebase";

export const fetchOrgID = async () => {
  let orgID_helper = null;
  let timezone_helper = "UTC";
  let rate_helper = "0.16";
  try {
    let querySnapshot = await getDocs(collection(db, "organizations"));
    querySnapshot.forEach((doc) => {
      if (doc.data().users.includes(uid)) {
        orgID_helper = doc.id;
        timezone_helper = doc.data().timezone;
        rate_helper = doc.data().rate;
        return {
          orgID: orgID_helper,
          timezone: timezone_helper,
          rate: rate_helper,
        };
      }
    });
  } catch (error) {
    console.error("Error fetching document:", error);
  }
  return { orgID: orgID_helper, timezone: timezone_helper, rate: rate_helper };
};

//note: might not need this and do everything inside fetchOrgID function
export const fetchOrganizationInfo = (setAllOrgs) => {
  const orgList = [];
  const getOrganizationInfo = async () => {
    let organizationInfo;
    //note: leaving because of the indexing thing
    organizationInfo = await getDocs(collection(db, "organizations"));

    const orgIDQ = query(collection(db, "organizations"));
    const orgIDsSnapshot = await getDocs(orgIDQ);
    orgIDsSnapshot.forEach((doc) => {
      const docData = doc._document.data.value.mapValue.fields;
      // console.log(docData, docData.name?.stringValue);
      orgList.push(docData);
    });
    setAllOrgs(orgList);
  };
  getOrganizationInfo();
};

export const fetchOrganizationIDs = (setAllOrgs) => {
  const getOrganizationIDs = async () => {
    const orgList = [];
    const orgDocs = await getDocs(collection(db, "organizations"));

    orgDocs.forEach((doc) => {
      const docData = doc._document.data.value.mapValue.fields;
      if ("child_orgs" in docData){
        orgList.push({'uid':docData['uid'].stringValue, 'name':docData['name'].stringValue,
          'child_orgs':docData['child_orgs'].arrayValue.values.map((obj) => Object.values(obj)[0])});
      }else{
        orgList.push({'uid':docData['uid'].stringValue, 'name':docData['name'].stringValue,})
      }
    });

    setAllOrgs(orgList);
  };
  getOrganizationIDs();
};

export const fetchSpecificTeamOrganizationIDs = (orgID, setAllOrgs) => {
  const getOrganizationIDs = async () => {
    const orgList = [];
    let childOrgs = []
    const orgDoc = await getDoc(doc(db, "organizations", orgID));
    if (orgDoc.exists()) {
      const orgData = orgDoc.data();
      if ("child_orgs" in orgData){
        childOrgs = orgData['child_orgs']
        orgList.push({'uid':orgData['uid'], 'name':orgData['name'],
          'child_orgs':orgData['child_orgs']})
      
        const q = query(collection(db, "organizations"), where("uid", "in", childOrgs));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          orgList.push({'uid':doc.data()['uid'], 'name':doc.data()['name'],})
        });
      }
    }
    setAllOrgs(orgList)
  };
  getOrganizationIDs();
};

export const orgSelectPress = (
  pickedOrg,
  event,
  setPickedOrg,
  setOrgEdit,
  setSelectedOrgID,
  setTimezone,
  setRate = null
) => {
  const previousOrg = pickedOrg;
  const displayOrg = event.target.value;
  setPickedOrg(displayOrg);
  setSelectedOrgID(displayOrg);
  localStorage.setItem("selectedOrgID", displayOrg);
  //setOrgName(orgName);
  const getSelectedTimezone = async () => {
    const orgTimezoneQ = query(
      collection(db, "organizations"),
      where("uid", "==", displayOrg)
    );
    const orgTimezoneSnapshot = await getDocs(orgTimezoneQ);
    orgTimezoneSnapshot.forEach((doc) => {
      let orgTimezone = doc.data().timezone;
      let orgRate = doc.data().rate;
      localStorage.setItem("timezone", orgTimezone);
      localStorage.setItem("rate", orgRate);
      setTimezone(orgTimezone);
      if (setRate) setRate(orgRate);
    });
  };
  getSelectedTimezone();
  setOrgEdit(false);
};

export const fetchOrganizationFromID = async (orgID) => {
  const orgRef = doc(db, "organizations", orgID);
  const orgDoc = await getDoc(orgRef);
  if (orgDoc.exists()) {
    console.log("Document data:", orgDoc.data());
    return orgDoc.data();
  } else {
    console.log("No such document!");
    return null;
  }
};

export const fetchProductTypesFromOrganization = async (
  orgID,
  setProdTypes
) => {
  try {
    const outletsRef = collection(db, `organizations/${orgID}/Outlets`);
    const querySnapshot = await getDocs(outletsRef);
    const productTypes = new Set();

    if (querySnapshot.empty) {
      console.log("There are no outlets.");
      setProdTypes(["All Outlets"]);
      return;
    }

    querySnapshot.forEach((doc) => {
      const outletData = doc.data();
      if (outletData.productType) {
        productTypes.add(outletData.productType);
      } else {
        productTypes.add("N/A");
      }
    });

    setProdTypes(Array.from(productTypes));
  } catch (error) {
    console.error("Error retrieving documents: ", error);
    setProdTypes(["All Outlets"]);
  }
};

// export const fetchIsOverviewAccount = async (orgID, setIsOverviewAccount) => {
//   console.log(orgID)
//   const orgRef = doc(db, "organizations", orgID);
//   const orgDoc = await getDoc(orgRef)
//   if (orgDoc.exists()) {
//     const orgData = orgDoc.data();
//     if ("child_orgs" in orgData){
//       console.log("yo")
//       if (Array.isArray(orgData.child_orgs) &&
//       orgData.child_orgs.length > 0){
//         console.log("ok")
//         return true;
//       }else{
//         return false;
//       }
//     }else{
//       console.log("ok");
//       return false;
//     }
//   } else {
//     console.log("No such document!");
//     return false;
//   }
//   setIsOverviewAccount(true);
// };

export const fetchIsOverviewAccount = (
  orgID,
  setIsOverviewAccount
) => {
  const getOverviewAccount = async () => {
    const orgRef = doc(db, "organizations", orgID);
    const orgDoc = await getDoc(orgRef)
    if (orgDoc.exists()) {
      const orgData = orgDoc.data();
      if ("child_orgs" in orgData){
        console.log("yo")
        if (Array.isArray(orgData.child_orgs) &&
        orgData.child_orgs.length > 0){
          console.log("ok")
          setIsOverviewAccount(true);
          return true;
        }else{
          return false;
        }
      }else{
        console.log("ok");
        return false;
      }
    } else {
      console.log("No such document!");
      return false;
    }
  }
  getOverviewAccount();
};

export const registerOrganization = async (organization) => {
  try {
    // Format the join date
    const formattedJoinDate = moment().format("YYYY-MM-DD");
    organization.joinDate = formattedJoinDate;

    const orgDocRef = doc(collection(db, "organizations"));
    organization.uid = orgDocRef.id;
    const zipcode_to_timezone = require("zipcode-to-timezone");
    const timezone = zipcode_to_timezone.lookup(organization.zipcode);

    // Set the document in Firestore with the organization data
    await setDoc(orgDocRef, {
      name: organization.name,
      rate: organization.rate,
      timezone: timezone,
      zipcode: organization.zipcode,
      address: organization.address,
      users: organization.users,
      joinDate: organization.joinDate,
      uid: organization.uid,
    });
    await setUsersOrgID(organization.uid, organization.users);
    console.log(
      "Organization successfully registered with ID:",
      organization.uid
    );
    return organization.uid
  } catch (error) {
    console.error("Error registering organization:", error);
    throw new Error("Failed to register organization.");
  }
};

const setUsersOrgID = async (orgID, users) => {
  try {
    for (const uid of users) {
      const userDocRef = doc(db, "users", uid);
      await updateDoc(userDocRef, {
        orgID: orgID,
      });

      console.log(`User ${uid} successfully updated with orgID: ${orgID}`);
    }
  } catch (error) {
    console.error("Error updating user documents with orgID:", error);
    throw new Error("Failed to update user documents with orgID.");
  }
};

export const updateOrganizationRate = async (orgID, newRate) => {
  try {
    const organizationDocRef = doc(db, "organizations",orgID);
    await updateDoc(organizationDocRef, {rate:newRate})
  } catch (error) {
    throw new Error("Failed to update rate: " + error.message);
  }
};