import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import OutletListContainer from "./outletsListContainer";
import UsageDashboardContainer from "./usageDashboardContainer";
import Header from "../../components/header";
import {
  fetchOrgID,
  fetchProductTypesFromOrganization,
  fetchIsOverviewAccount,
} from "../../functions/organization";
import { fetchOutlets } from "../../functions/outletList";
import { fetchWattHours, fetchAverageUsage } from "../../functions/wattHours";
import OrganizationPicker from "../../components/orgPicker";
import { green, greyDark, greyLight, greyUltraLight } from "../../lib/colors";
import { fetchUserRole } from "../../functions/users";
import { collection, getDocs } from "firebase/firestore";
import db from "../../firebase";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function HomeScreen() {
  const [user, waiting, error] = useAuthState(auth);
  const navigate = useNavigate();
  const [orgID, setOrgID] = useState(localStorage.getItem("orgID"));
  const [timezone, setTimezone] = useState(
    localStorage.getItem("timezone") || "UTC"
  );
  const [rate, setRate] = useState(localStorage.getItem("rate") || "0.16");
  const [outlets, setOutlets] = useState(
    JSON.parse(localStorage.getItem("outlets"))
  );
  const [wattHours, setWattHours] = useState([]);
  const [outletsLoading, setOutletsLoading] = useState(true);
  const [wattsLoading, setWattsLoading] = useState(true);
  const [selectedOrgID, setSelectedOrgID] = useState(
    localStorage.getItem("selectedOrgID")
  );
  const [outletAverageUsage, setOutletAverageUsage] = useState(
    JSON.parse(localStorage.getItem("outletAverageUsage"))
  );
  const [prodTypes, setProdTypes] = useState([]);
  const [allProdTypes, setAllProdTypes] = useState([]);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [roleLoading, setRoleLoading] = useState(true);
  const [isOverviewAccount, setIsOverviewAccount] = useState(false);

  // This is for filtering
  const [selectedApplianceType, setSelectedApplianceType] = useState("");
  const handleApplianceTypeChange = (event) => {
    const selectedApplianceType = event.target.value;
    setSelectedApplianceType(selectedApplianceType);
    console.log("Fetching...");
    if (selectedOrgID) {
      fetchWattHours(
        selectedOrgID,
        setWattHours,
        setWattsLoading,
        null,
        selectedApplianceType
      );
      fetchAverageUsage(
        selectedOrgID,
        setOutletAverageUsage,
        null,
        selectedApplianceType
      );
    } else {
      fetchWattHours(
        orgID,
        setWattHours,
        setWattsLoading,
        null,
        selectedApplianceType
      );
      fetchAverageUsage(
        orgID,
        setOutletAverageUsage,
        null,
        selectedApplianceType
      );
    }
    console.log("Fetched!!");
  };

  let productTypes = [];

  // resize function useEffect
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // gets userRole
  useEffect(() => {
    if (waiting) {
      // maybe trigger a loading screen
      return;
    }
    const getRole = async () => {
      const userRole = await fetchUserRole(user.uid);
      setRoleLoading(false);
      if (userRole == "pending") {
        navigate("/pending-role");
      }
    };
    if (user) {
      getRole();
    } else {
      navigate("/login");
    }
  }, [user, waiting]);

  // gets OrgID
  useEffect(() => {
    // // Function to fetch data from Firestore
    const fetchAndSetOrgID = async () => {
      if (!orgID) {
        const {
          orgID: fetchedOrgID,
          timezone: fetchedTimezone,
          rate: fetchedRate,
        } = await fetchOrgID();
        if (fetchedOrgID) {
          setOrgID(fetchedOrgID);
          localStorage.setItem("orgID", fetchedOrgID);
        }
        if (fetchedTimezone) {
          setTimezone(fetchedTimezone);
          localStorage.setItem("timezone", fetchedTimezone);
        }
        if (fetchedRate) {
          setRate(fetchedRate);
          localStorage.setItem("rate", fetchedRate);
        }
      }
    };
    fetchAndSetOrgID();
  }, [orgID]);

  // gets information upon orgID load
  useEffect(() => {
    if (orgID && selectedOrgID === null) {
      fetchOutlets(orgID, timezone, setOutlets, setOutletsLoading, setProdTypes);
      fetchWattHours(orgID, setWattHours, setWattsLoading);
      fetchAverageUsage(orgID, setOutletAverageUsage);
    }
  }, [orgID, timezone]);

  // gets information upon selectedOrgID change
  useEffect(() => {
    if (selectedOrgID) {
      fetchOutlets(selectedOrgID, timezone, setOutlets, setOutletsLoading, setProdTypes); // Call fetchData function when component mounts
      fetchWattHours(selectedOrgID, setWattHours, setWattsLoading);
      fetchAverageUsage(selectedOrgID, setOutletAverageUsage);
    }
  }, [selectedOrgID, timezone]); //runs when these state variables change

  // gets ALL product type info
  useEffect(() => {
    const fetchProductTypes = async () => {
      try {
        // Access a Firestore document
        let querySnapshot = await getDocs(collection(db, "productTypes"));
        let productTypesSet = new Set();
        querySnapshot.forEach((doc) => {
          productTypesSet.add(doc.id);
        });
        // Convert Set back to array and add "N/A" at the beginning
        let productTypes = ["N/A", ...Array.from(productTypesSet)];
        setAllProdTypes(productTypes);
        localStorage.setItem("productTypes", JSON.stringify(productTypes));
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };
    fetchProductTypes(); // Call fetchData function when component mounts
  }, []); // Empty dependency array ensures useEffect runs only once on mount

  useEffect(() => {
    if (selectedOrgID) {
      fetchIsOverviewAccount(selectedOrgID, setIsOverviewAccount)
    }else if(orgID){
      fetchIsOverviewAccount(orgID, setIsOverviewAccount)//setIsOverviewAccount(fetchIsOverviewAccount(orgID))
    }
  }, [orgID, selectedOrgID]);

  // Render based on loading state
  return (
    <div>
      {outletsLoading ? (
        // <div>Loading...</div>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100vw"
          height="100vh"
          position="fixed"
          top={0}
          left={0}
        >
          <Header orgID={orgID} />
          <CircularProgress />
        </Box>
      ) : (
        <div
          style={{
            marginTop: 75,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Header orgID={orgID} />
          {/* this will only be visible if the base orgID matches LZqI3R6MInUuwtgtROPK */}
          {(isOverviewAccount == true || orgID=='LZqI3R6MInUuwtgtROPK') && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "3%",
                }}
              >
                <OrganizationPicker
                  orgID={orgID}
                  selectedOrgID={selectedOrgID}
                  setSelectedOrgID={setSelectedOrgID}
                  setTimezone={setTimezone}
                  setRate={setRate}
                  setSelectedApplianceType={setSelectedApplianceType}
                />

              </Box>
            )}
          {orgID ? (
            <div
              style={{
                width: windowDimensions.width < 1400 ? "92.5vw" : "1300px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {wattsLoading ? (
                <div>Loading...</div>
              ) : Object.keys(outlets).length === 0 ? (
                <div></div>
              ) : (
                <Box>
                  <UsageDashboardContainer
                    orgID={selectedOrgID ? selectedOrgID : orgID}
                    outlets={
                      selectedApplianceType &&
                      selectedApplianceType != "" &&
                      selectedApplianceType !== "All Outlets"
                        ? selectedApplianceType == "N/A"
                          ? outlets.filter(
                              (outlet) => outlet.productType === ""
                            )
                          : outlets.filter(
                              (outlet) =>
                                outlet.productType === selectedApplianceType
                            )
                        : outlets
                    }
                    wattHours={wattHours}
                    outletAverageUsage={outletAverageUsage}
                    height={windowDimensions.height}
                    width={windowDimensions.width}
                    selectedApplianceType={selectedApplianceType}
                    handleApplianceTypeChange={handleApplianceTypeChange}
                    prodTypes={prodTypes}
                    rate={rate}
                  />
                </Box>
              )}
              <div
                style={{
                  marginTop: windowDimensions.width < 1325 ? "3.5vw" : "2%",
                  width: "100%",
                }}
              >
                <OutletListContainer
                  outlets={
                    selectedApplianceType &&
                    selectedApplianceType != "" &&
                    selectedApplianceType !== "All Outlets"
                      ? selectedApplianceType == "N/A"
                        ? outlets.filter((outlet) => outlet.productType === "")
                        : outlets.filter(
                            (outlet) =>
                              outlet.productType === selectedApplianceType
                          )
                      : outlets
                  }
                  prodTypes={allProdTypes}
                  setOutlets={setOutlets}
                  timezone={timezone}
                  wattHours={wattHours}
                  rate={rate}
                  height={windowDimensions.height}
                  width={windowDimensions.width}
                />
              </div>
            </div>
          ) : (
            <div>No data available</div>
          )}
        </div>
      )}
    </div>
  );
}
