import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import logo from "../../assets/images/logo.png";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Badge from "@mui/material/Badge";
import { Box, AppBar, Toolbar, Button } from "@mui/material";

const Header = () => {
  const orgID = localStorage.getItem("orgID")
  const [user, waiting] = useAuthState(auth);
  const [activePage, setActivePage] = useState(
    window.location.pathname.split("/")[1] || "dashboard"
  );
  const navigate = useNavigate();

  const toggleMenu = (pageName) => {
    setActivePage(pageName.toLowerCase());
    navigate("/" + (pageName === "Dashboard" ? "" : pageName.toLowerCase()), {
      state: { orgID },
    });
  };

  useEffect(() => {
    if (waiting) return;
    if (!user) navigate("/login");
  }, [navigate, user, waiting]);

  const adminSections = [
    "Dashboard",
    "Schedules",
    "Outlets",
    "Inventory",
    "Organizations",
  ];
  const sections = ["Dashboard", "Schedules"];

  return (
    <AppBar
      position="fixed"
      color="default"
      sx={{
        boxShadow: "none",
        borderBottom: "1px solid #E0E0E0",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1100,
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between", padding: "0 24px" }}>
        <Box display="flex" alignItems="center">
          <img
            src={logo}
            alt="ReVert Logo"
            style={{
              width: "120px",
              height: "auto",
              marginRight: "20px",
              marginBottom: '5px',
            }}
          />
          <nav style={{ display: "flex", alignItems: "center" }}>
            {orgID === "LZqI3R6MInUuwtgtROPK"
              ? adminSections.map((item) => (
                  <Button
                    key={item}
                    onClick={() => toggleMenu(item)}
                    sx={{
                      fontSize: "16px",
                      textTransform: "none",
                      fontFamily: 'Roboto, sans-serif',
                      fontWeight:500,
                      color:
                        activePage === item.toLowerCase()
                          ? "#4A4A4A"
                          : "#666666",
                      borderBottom:
                        activePage === item.toLowerCase()
                          ? "2px solid #4A4A4A"
                          : "2px solid transparent",
                      marginLeft: "15px",
                      borderRadius:"0px",
                      paddingBottom: "5px",
                      "&:hover": {
                        backgroundColor: "transparent",
                        color: "#4A4A4A",
                      },
                    }}
                  >
                    {item}
                  </Button>
                ))
              : sections.map((item) => (
                  <Button
                    key={item}
                    onClick={() => toggleMenu(item)}
                    sx={{
                      fontSize: "16px",
                      textTransform: "none",
                      fontFamily: 'Roboto, sans-serif',
                      fontWeight:500,
                      color:
                        activePage === item.toLowerCase()
                          ? "#4A4A4A"
                          : "#666666",
                      borderBottom:
                        activePage === item.toLowerCase()
                          ? "2px solid #4A4A4A"
                          : "2px solid transparent",
                      marginLeft: "15px",
                      borderRadius:"0px",
                      paddingBottom: "5px",
                      "&:hover": {
                        backgroundColor: "transparent",
                        color: "#4A4A4A",
                      },
                    }}
                  >
                    {item}
                  </Button>
                ))}
          </nav>
        </Box>

        <Box display="flex" alignItems="center">
          {/*<Badge sx={{ marginRight: "20px" }}>
            <NotificationsIcon
              style={{ fontSize: "28px", cursor: "pointer", color: "#4A4A4A" }}
            />
          </Badge>*/}
          <AccountCircleIcon
            style={{
              fontSize: "32px",
              cursor: "pointer",
              color: "#4A4A4A",
            }}
            onClick={() => navigate("/organizations/profile")}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
