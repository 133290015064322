import React, { useEffect, useState } from "react";
import { Box, Button, Typography, CircularProgress, Backdrop, Alert, Chip, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import {
  assignDeviceToOrganization,
  createOutletsInOrganization,
  deleteNewDevice,
  fetchNewDevices,
} from "../../functions/inventory";
import { green, greenDark, blue, blueDark, blueAlternative, blueDarkAlternative } from "../../lib/colors";

export default function AssignOutletsScreen() {
  const location = useLocation();
  const registeredID = location.state?.registeredID;

  const [user, waiting] = useAuthState(auth);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const navigate = useNavigate();
  const orgID = localStorage.getItem("orgID");

  const [newDevices, setNewDevices] = useState([]);
  const [devicesToAssign, setDevicesToAssign] = useState([]);

  useEffect(() => {
    if (waiting) return;
    if (!user || !orgID) navigate("/login");
  }, [user, waiting]);

  const getNewDevices = async () => {
    setLoading(true);
    const devicesData = await fetchNewDevices();

    const sortedDevices = devicesData.sort((a, b) => a.device.localeCompare(b.device));

    setNewDevices(sortedDevices);
    setLoading(false);
  };

  useEffect(() => {
    getNewDevices();
  }, []);

  const handleRowClick = (params) => {
    const device = newDevices[params.id];
    setDevicesToAssign((prev) => {
      if (prev.includes(device)) {
        return prev.filter((d) => d !== device);
      } else {
        return [...prev, device];
      }
    });
  };

  const handleDeleteDevice = (deviceToDelete) => {
    setDevicesToAssign((prev) => prev.filter((device) => device !== deviceToDelete));
  };

  const handleClearAll = () => {
    setDevicesToAssign([]);
  };

  const handleAssignDevices = async () => {
    setProcessing(true);
    const activationDate = getCurrentDateFormatted();

    try {
      for (let i = 0; i < devicesToAssign.length; i++) {
        const currentDevice = devicesToAssign[i];
        const currentId = currentDevice.id;

        // Assign the device to the organization
        await assignDeviceToOrganization(registeredID, currentId);

        // Create outlets for the assigned device
        await createOutletsInOrganization(
          registeredID,
          currentId,
          activationDate
        );

        // Delete the device from the new devices list
        await deleteNewDevice(currentId);

        console.log(`Device ${currentId} assigned and processed successfully.`);
      }

      // Show success alert
      setShowSuccessAlert(true);
      setTimeout(() => {
        setShowSuccessAlert(false);
        navigate("/organizations");
      }, 2000);
    } catch (error) {
      console.error("Error during device assignment:", error);
      window.alert("An error occurred while assigning outlets. Please try again.");
    } finally {
      setProcessing(false);
    }
  };

  const getCurrentDateFormatted = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const isRowSelected = (device) => devicesToAssign.includes(device);

  const columns = [
    { field: "device", headerName: "Device Name", flex: 1 },
    { field: "location", headerName: "Location", flex: 1 },
  ];

  const rows = newDevices.map((device, index) => ({
    id: index,
    device: device.device,
    location: device.Location,
  }));

  return (
    <Box
      sx={{
        marginTop: 8,
        padding: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Typography variant="h5" align="center" gutterBottom>
        Assign Outlets
      </Typography>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={{ height: 600, width: "60%", maxWidth: 600 }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              onRowClick={handleRowClick}
              getRowClassName={(params) =>
                isRowSelected(newDevices[params.id]) ? "selected-row" : ""
              }
              sx={{
                "& .selected-row": {
                  backgroundColor: blueAlternative,
                  "&:hover": {
                    backgroundColor: blueDarkAlternative,
                  },
                },
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: 4,
              width: "60%",
              maxWidth: 600,
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: '80%',
                backgroundColor: devicesToAssign.length > 0 ? blueAlternative : 'red',
                '&:hover': {
                  backgroundColor: devicesToAssign.length > 0 ? blueDarkAlternative : '#b71c1c', // Darker red on hover
                },
              }}
              onClick={() => {
                if (devicesToAssign.length > 0) {
                  console.log("Saving selected devices: ", devicesToAssign);
                  handleAssignDevices();
                } else {
                  navigate("/organizations"); // Skip and go to the organizations page
                }
              }}
            >
              {devicesToAssign.length > 0 ? "Save" : "Skip"}
            </Button>
          </Box>
          <Box sx={{ marginTop: 2, width: "60%", maxWidth: 600 }}>
            <Typography variant="subtitle1">
              Selected Outlets:
            </Typography>
            <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', maxHeight: '150px', overflowY: 'auto', padding: 1, borderRadius: 1, backgroundColor: '#f5f5f5' }}>
              {devicesToAssign.length > 0 ? (
                devicesToAssign.map((device) => (
                  <Chip
                    key={device.id}
                    label={`${device.device} - ${device.Location}`} 
                    onDelete={() => handleDeleteDevice(device)}
                    color="primary"
                  />
                ))
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No outlets selected
                </Typography>
              )}
            </Stack>
            {devicesToAssign.length > 0 && (
              <Button onClick={handleClearAll} sx={{ marginTop: 1 }}>
                Clear All
              </Button>
            )}
          </Box>
        </>
      )}
      {processing && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
          open={processing}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {showSuccessAlert && (
        <Alert
          severity="success"
          sx={{
            position: "fixed",
            top: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: (theme) => theme.zIndex.drawer + 2,
          }}
        >
          Outlets have been successfully assigned!
        </Alert>
      )}
    </Box>
  );
}
