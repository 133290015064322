import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Typography,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Tooltip,
} from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import DeleteIcon from "@mui/icons-material/Delete";
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import { green } from "../../lib/colors";
import { deleteFullUser, updateUserRole } from "../../functions/users";
import { removeUserFromOrganization } from "../../functions/users";
const renderCellContent = (params) => {
  const hasNoData = params.value === "No data";
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      {params.value}
      {hasNoData && <ReportProblemIcon style={{ color: "grey" }} />}
    </div>
  );
};

const ActionCell = ({ id, onDelete, onRoleChanged, userRole }) => {
  const isOwner = userRole === "owner";
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "right",
        alignItems: "center",
        width: "100%",
        height: "100%",
        gap: "10px",
      }}
    >
      <Tooltip title="Assign role">
        <LocalPoliceIcon
          style={{ cursor: isOwner ? "not-allowed" : "pointer", color: isOwner ? "grey" : green }}
          onClick={() => !isOwner && onRoleChanged(id)}
        />
      </Tooltip>
      <Tooltip title="Delete from list">
        <DeleteIcon
          style={{ cursor: isOwner ? "not-allowed" : "pointer", color: isOwner ? "grey" : "red", marginRight: "15px" }}
          onClick={() => !isOwner && onDelete(id)}
        />
      </Tooltip>
    </Box>
  );
};

const UsersDataGridTable = ({ data, loading, getUsers, orgId }) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [selectedRole, setSelectedRole] = useState("");
  const roles = ["admin", "member"]

  const handleDeleteClick = (id) => {
    setCurrentId(id);
    setDeleteDialogOpen(true);
  };

  const handleAssignClick = (id) => {
    setCurrentId(id);
    setAssignDialogOpen(true);
  };


  const handleDeleteConfirm = async() => {
    console.log(`Deleting user with id: ${currentId}`);
    await deleteFullUser(currentId)
    await removeUserFromOrganization(currentId, orgId);
    getUsers();
    setDeleteDialogOpen(false);
  };

  const handleAssignRoleConfirm = async () => {
    setAssignDialogOpen(false);
    try {
      await updateUserRole(currentId, selectedRole);
      await getUsers();
    } catch (error) {
      console.error("Error handling role assignment:", error);
    }
  };

  const rows = Object.values(data).map((user) => ({
      id: user.uid,
      email: user.email,
      role: user.role
    }));

  const columns = [
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderCell: renderCellContent,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      renderCell: renderCellContent,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <ActionCell
          id={params.id}
          onRoleChanged={handleAssignClick}
          onDelete={handleDeleteClick}
          userRole={params.row.role}
        />
      ),
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h5" component="div" sx={{ mb: 2, fontWeight: "bold" }}>
        Users
      </Typography>
      <Box sx={{ width: "70%", height: "80vh" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          loading={loading}
          disableExtendRowFullWidth
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
              "& .MuiDataGrid-cell": {
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#f5f5f5",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              },
            },
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "name", sort: "asc" }],
            },
          }}
        ></DataGrid>
      </Box>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={assignDialogOpen}
        onClose={() => setAssignDialogOpen(false)}
      >
        <DialogTitle>Assign role</DialogTitle>
        <DialogContent>
          <Select
            value={selectedRole}
            onChange={(e) => setSelectedRole(e.target.value)}
            fullWidth
          >
            {roles.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAssignDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleAssignRoleConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UsersDataGridTable;
